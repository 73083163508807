import React from "react"

import { OutboundLink } from "gatsby-plugin-google-gtag"
import { ArrowRightIcon } from "@primer/octicons-react"

import brendan from "images/Brendan_Maginnis.jpg"
import nick from "images/nick_thapen.jpg"
import tim from "images/tim_gilboy.jpg"
import team from "images/team-sourcery.png"
import reka from "images/reka.jpeg"
import ben from "images/ben-martineau.jpeg"
import ruan from "images/ruan-comelli.jpeg"

import forwardPartners from "images/investors/forward-partners.png"
import techstars from "images/investors/techstars.png"
import angelInvest from "images/investors/angel-invest.svg"
import runa from "images/investors/runa.png"
import acecap from "images/investors/acecap.webp"

import SEO from "components/seo"

export default function AboutPage() {
  const ourValues = [
    {
      title: "Developer First",
      description:
        "We earn and keep the trust of the developers we work with by putting their interests first.",
    },
    {
      title: "Enjoyment",
      description:
        "We strive to enjoy every day at Sourcery and help others to do so too. Everyone feels at home at Sourcery. We act kindly to each other and always assume best intentions. We recognize and celebrate wins everywhere we see them.",
    },
    {
      title: "Collaboration",
      description:
        "We collaborate with everyone within Sourcery to create shared success, trust, and belonging. We inform each other of our progress and reach out for help when we need it.",
    },
    {
      title: "Transparency",
      description:
        "We are open about as many things as possible. By making information available, we enable everyone around us to make better decisions.",
    },
  ]

  const ourTeam = [
    {
      name: "Brendan Maginnis",
      role: "Founder, CEO",
      image: brendan,
      linkedin: "https://www.linkedin.com/in/brendan-maginnis/",
    },
    {
      name: "Nicholas Thapen",
      role: "Founder, CTO",
      image: nick,
      linkedin: "https://www.linkedin.com/in/nicholas-thapen/",
    },
    {
      name: "Tim Gilboy",
      role: "Founder, COO",
      image: tim,
      linkedin: "https://www.linkedin.com/in/timgilboy/",
    },
    {
      name: "Reka Horvath",
      role: "Senior Software Engineer",
      image: reka,
      linkedin: "https://www.linkedin.com/in/reka-horvath-dev/",
    },
    {
      name: "Ben Martineau",
      role: "Senior Software Engineer",
      image: ben,
      linkedin: "https://www.linkedin.com/in/benhmartineau/",
    },
    {
      name: "Ruan Comelli",
      role: "Senior Software Engineer",
      image: ruan,
      linkedin: "https://www.linkedin.com/in/ruan-comelli-430097194/",
    },
  ]

  const usefulLinks = [
    {
      title: "Company handbook",
      description: "Our Open Source guide to working at Sourcery.",
      href: "https://handbook.sourcery.ai/",
    },
    {
      title: "GitHub Repo",
      description: "The magic of Sourcery.",
      href: "https://github.com/sourcery-ai/sourcery",
    },
    {
      title: "Documentation",
      description: "How to get started and use Sourcery.",
      href: "https://docs.sourcery.ai/",
    },
    {
      title: "LinkedIn",
      description: "Our team and company news.",
      href: "https://www.linkedin.com/company/sourcery-ai",
    },
  ]

  const investorsLogo = [forwardPartners, techstars, angelInvest, runa, acecap]

  return (
    <div>
      <SEO
        title="Sourcery | Automatically Improve Python Code Quality"
        description="Sourcery instantly refactors your Python code. Try Sourcery for free to improve the quality of your code and speed up your team's development."
        keywords={[
          `sourcery`,
          `refactoring`,
          `developer tools`,
          `python`,
          `ai`,
        ]}
      />
      <div className=" bg-black-500 bg-about-pattern">
        <div className="max-w-[87.5rem] px-8 mx-auto ">
          {/* Hero */}
          <div className="flex pt-10 lg:px-0 flex-wrap">
            <div className="text-white pt-6 tracking-tight max-w-xs lg:max-w-sm">
              <h1 className="text-5xl lg:text-7xl">Our history</h1>
              <h3 className="text-3xl lg:text-5xl mt-7">
                Making <span className="text-purple-200">magic</span> happen for
                devs
              </h3>
            </div>
            <div className="flex flex-col sm:flex-row items-start gap-5 sm:gap-8 py-7 lg:py-9 lg:ml-9 text-black-200 tracking-tight leading-6">
              <div className="max-w-xs">
                <p>
                  Software developers have a magical ability to create something
                  out of nothing - to bring ideas to life and to inspire others.
                  But, poor quality code and technical debt slow teams down to
                  the point that that magic goes away. We’re a team of
                  developers who’ve lived and breathed the pains of codebases
                  slowed down by technical debt and are on a mission to make
                  that a thing of the past.
                </p>
              </div>
              <div className="max-w-xs">
                <p>
                  We’re just at the beginning of our journey to continually
                  shift code quality further left. A developer on a typical team
                  spends more than a quarter of their time dealing with legacy
                  code and technical debt, and teams with high technical debt
                  are only able to spend 50% of the time on new features that a
                  team with low technical debt can. This shouldn’t be the way
                  software development is done.
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center px-6">
            <img src={team} className="" alt="Team photo" />
          </div>
          {/* Values */}
          <div className="bg-black-100 rounded-t-2xl py-8 lg:py-9 px-6 lg:px-[4.75rem] mx-auto">
            <h3 className="font-bold mb-7 text-black-500 text-3xl">
              Our values
            </h3>
            <div className="w-full flex flex-wrap flex-1 lg:flex-nowrap items-stretch justify-between mx-auto">
              {ourValues.map((value, index) => (
                <div
                  className="w-full md:w-[18rem] bg-white py-7 pl-7 pr-6 rounded-sm mb-4 md:m-4"
                  key={index}
                >
                  <div className="w-7 h-7 bg-pink-100 text-pink-200 rounded-full flex justify-center items-center">
                    <i className="mi-bookmark" />
                  </div>
                  <div className="tracking-tight leading-6">
                    <p className="font-bold mt-5 mb-3 text-black-500">
                      {value.title}
                    </p>
                    <p className="text-black-300">{value.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Team */}
          <div className="bg-white py-8 lg:py-9 px-6 lg:px-8 bg-red-pattern">
            <div className="grid md:grid-cols-2">
              <div className="max-w-sm leading-6">
                <h3 className="font-bold mb-7 text-black-500 text-3xl">
                  Our team
                </h3>
                <p className="mt-5 text-black-300">
                  Our team has led and delivered high quality software projects
                  in startups, enterprise and academia. We’ve all lived and
                  experienced the frustrations of having to deal with technical
                  debt and poor quality code and are determined to make that a
                  thing of the past.
                </p>
                <p className="mt-5 text-black-300">
                  We work remotely and are currently spread out between Austria,
                  Brazil, and the UK.
                </p>
                <OutboundLink
                  href="https://sourcery.notion.site/Sourcery-is-hiring-f53222a98ece43099e94e3536c943c19"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="flex justify-between items-center bg-pink-100 font-bold text-sm mt-6 px-5 py-[0.906rem] max-w-[16rem] rounded-sm hover:bg-opacity-90 ">
                    <p className="text-black-500 mr-4">View open vacancies</p>
                    <p className="text-pink-200">
                      <ArrowRightIcon size={16} />
                    </p>
                  </button>
                </OutboundLink>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mt-8 lg:mt-5">
                {
                  // team members
                  ourTeam.map((member, index) => (
                    <div key={index}>
                      <img
                        src={member.image}
                        alt={member.name}
                        className="w-[181px] h-[181px] object-cover rounded-sm"
                      />
                      <div className="pt-5 leading-6 tracking-tight">
                        <p className="font-bold">{member.name}</p>
                        <p>{member.role}</p>
                        <OutboundLink
                          href={member.linkedin}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <p className="text-pink-200 hover:text-black-200">
                            LinkedIn →
                          </p>
                        </OutboundLink>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          {/* Investors */}
          <div className="bg-black-100 py-8 lg:py-9 px-6 lg:px-8">
            <h3 className="font-bold mb-5 text-black-500 text-3xl text-center">
              Backed by incredible investors
            </h3>
            <div className="flex flex-col flex-wrap lg:flex-nowrap space-y-7 lg:space-y-0 justify-between items-center sm:flex-row mt-8">
              {investorsLogo.map((investor, index) => (
                <img
                  className="max-w-[10rem] space-x-6"
                  src={investor}
                  key={index}
                />
              ))}
            </div>
          </div>
          {/* Usefull Links */}
          <div className="bg-white rounded-b-2xl py-8 lg:py-9 px-4 lg:px-8">
            <h3 className="font-bold mb-7 text-black-500 text-3xl text-center">
              Useful links
            </h3>
            <div className="flex flex-wrap lg:flex-nowrap mx-auto justify-center">
              {usefulLinks.map((value, index) => (
                <div
                  className="w-full md:w-[18rem] bg-black-100 rounded-sm m-4 hover:translate-y-[-0.5rem] transition-all duration-300"
                  key={index}
                >
                  <OutboundLink
                    href={value.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="py-7 pl-7 pr-6">
                      <div className="w-7 h-7 bg-black-400 text-black-200 rounded-full flex justify-center items-center">
                        <i className="mi-bookmark" />
                      </div>
                      <div className="tracking-tight leading-6">
                        <p className="font-bold mt-5 text-black-500">
                          {value.title}
                        </p>
                        <p className="text-black-300">{value.description}</p>
                      </div>
                    </div>
                  </OutboundLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
